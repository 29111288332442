.Auth-modal-cont {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  will-change: transform, opacity;
}

#auth-modal .close {
  position: absolute !important;
  top: -1px !important;
  right: 11px !important;
  font-size: 3.6rem !important;
  font-weight: 200 !important;
  color: #868686 !important;
}
