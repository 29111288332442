.Phone .tel-adjust {
  height: 52px;
  padding-top: 21px;
  padding-bottom: 0;
  border-radius: 0;
}

.Phone .react-phone-number-input__icon {
  line-height: 1px;
}

.Phone .react-phone-number-input__phone {
  border-bottom: none !important;
  padding-top: 4px;
  width: calc(100% - 50px) !important;
  font-size: 18px !important;
}

.Phone .PhoneInputCountryIcon--border svg {
  vertical-align: top !important;
}
