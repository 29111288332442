.Label-floating {
  font-size: 14px;
  color: #9b9b9b;
  position: absolute;
  left: 10px;
  z-index: 4;
  font-weight: normal;
  margin-bottom: 0px;
  padding-top: 5px;
}
