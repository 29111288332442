.FormsInput-label {
  font-size: 14px;
  color: #5f5f5f;
  position: absolute;
  left: 7px;
  top: 1px;
  padding-left: 1px;
  z-index: 4;
  font-weight: normal;
  margin-bottom: 3px;
  padding-top: 4px;
}

.FormsInput-input {
  height: 53px!important;
  color: #3a3838;
  font-size: 18px;
  padding: 18px 8px 0!important;
  border-color: #c4c5c9;
  margin: -2px;
  background: #fafafa;
  font-weight: 600;
  border-radius: 3px !important;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  margin-left: -1px;
  padding-top: 10px;
}
