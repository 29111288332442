.Submit .ba-btn {
  text-transform: uppercase;
  color: #f1f1f1;
  border: 1px solid #FBB51C;
  height: 54px;
  padding: 5px 10px;
  background: #FBB51C;
  display: block;
  border-radius: 0px;
  /* letter-spacing: 2px; */
}
.Submit .ba-btn:hover {
  background-color: #F5A623 !important;
  border: 1px solid #F5A623;
  color: #f1f1f1;
}
.Submit .ba-btn:disabled {
  opacity: .65;
  cursor: not-allowed;
  background-color: #FBB51C;
}

.Submit .full-width {
  width: 100%;
  /* min-width: 288px; */
}
