input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0px 1000px #f9fbfd inset !important;
  -webkit-box-shadow: 0 0 0px 1000px #f9fbfd inset !important;
  -webkit-text-fill-color: rgb(72 72 72) !important;
}

html {
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  font-family: din-2014, sans-serif;
  color: #4a4a4a;
  letter-spacing: 0.16px;
  -webkit-font-smoothing: antialiased;
  min-height: 600px;
  font-size: 1.25rem;
  line-height: 1.25rem;
}
a {
  color: #337ab7;
  text-decoration: none;
}
.sprites {
  background-image: url('/images/sprites_v2.png');
  background-repeat: no-repeat;
}
.sprites.icon-play {
  background-position: -50px -258px;
  width: 27px;
  height: 27px;
}
.sprites.icon-mobile {
  background-position: -271px -42px;
  width: 57px;
  height: 75px;
}
.sprites.icon-qr {
  background-position: -262px -141px;
  width: 65px;
  height: 75px;
}
.sprites.icon-cash {
  background-position: -260px -233px  ;
  width: 68px;
  height: 75px;
}
.sprites.icon-fb {
  background-position: -282px -340px;
  width: 24px;
  height: 49px;
  zoom: 0.4;
}
.sprites.icon-twitter {
  background-position: -271px -439px;
  width: 48px;
  height: 41px;
  zoom: 0.4;
}
.sprites.icon-ig {
  background-position: -270px -533px;
  width: 50px;
  height: 49px;
  zoom: 0.4;
}
.sprites.icon-linkedin {
  background-position: -270px -628px;
  width: 51px;
  height: 49px;
  zoom: 0.5;
}
.sprites.icon-clock-green {
  background-position: -48px -121px;
  min-width: 31px;
  min-height: 31px;
  zoom: 0.7;
}
.sprites.icon-clock-red {
  background-position: -136px -121px;
  min-width: 31px;
  min-height: 31px;
  zoom: 0.7;
}
.sprites.icon-btc {
  background-position: -136px -255px;
  width: 33px;
  height: 33px;
  zoom: .8;
}
.sprites.icon-pointer {
  background-position: -142px -190px;
  width: 20px;
  height: 31px;
}
.sprites.icon-pointer.zoom6 {
  zoom: 0.6;
}
.sprites.icon-pointer-white {
  background-position: -52px -190px;
  width: 55px;
  /* width: 21px; */
  height: 31px;
}
.sprites.icon-lock {
  background-position: -50px -410px;
  width: 26px;
  height: 24px;
}
.sprites.icon-cogs {
  background-position: -139px -410px;
  width: 26px;
  height: 24px;
}
.sprites.icon-list {
  background-position: -50px -473px;
  width: 26px;
  height: 25px;
}
.sprites.icon-headphone {
  background-position: -139px -473px;
  width: 26px;
  height: 25px;
}
.sprites.icon-marker {
  background-position: -48px -42px;
  min-width: 31px;
  height: 47px;
  zoom: 0.7;
}
.map-pin-icon {
  background-image: url('/images/map-pin.png');
  background-repeat: no-repeat;
  width: 23px;
  height: 47px;
  background-size: 100%;
}
.map-pin-icon.small {
  width: 16px
}
.geosuggest__suggests {
  position: absolute;
  left: 0px;
  right: 0px;
  padding: 0;
  list-style: none;
  z-index: 5;
  background: white;
  border: 1px solid #E4E9E9;
}
.geosuggest__suggests--hidden {
  border: 0;
  max-height: 0;
  overflow: hidden;
}
.geosuggest__item {
  padding: 10px;
  cursor: pointer;
  color: #364b5e;
}
.geosuggest__item--active {
  background-color: #485f77;
  color: white;
}
.geosuggest__item:hover:not(.geosuggest__item--active) {
  color: white;
  background-color: #A9A9A9;
}
.geosuggest__item:not(:last-child) {
  border-bottom: 1px solid #E4E9E9;
}

/*  General btns color */
.btn-color-FBB51C {
  background-color: #FBB51C;
  color: #fff;
}

/*  CSS from styles.css */
.conversion-title {
  font-size: 1.1em;
  font-weight: 600;
  color: #485f77;
  text-align: center;
}
.ba-input-lg {
    height: 52px !important;
    color: #4a4a4a;
    font-size: 18px;
    border-radius: 0 !important;
    padding: 25px 8px 0 !important;
    border-color: #E4E9E9;
}
.ba-input-lg-addon {
  width: auto;
  height: 52px !important;
  color: #fff !important;
  border-radius: 0;
}
.input-label-floating {
    font-size: 14px;
    color: #9b9b9b;
    position: absolute;
    /*top: 25px;*/
    left: 20px;
    z-index: 4;
    font-weight: normal;
    margin-bottom: 0px;
    padding-top: 5px;
}
.ba-btn {
  text-transform: uppercase;
  /* color: #f1f1f1; */
  color: #222;
  border: 1px solid #FBB51C;
  /* width: 320px; */
  height: 54px;
  padding: 5px 10px;
  background: #FBB51C;
  display: block;
  margin: 0 auto;
  border-radius: 0px;
  /* letter-spacing: 2px; */
  cursor: pointer;
}
.ba-btn:hover {
  background-color: #F5A623 !important;
  border: 1px solid #F5A623;
  color: #f1f1f1;
}
.ba-btn:disabled {
  opacity: .65;
  cursor: not-allowed;
  background-color: #FBB51C;
}
.link-look {
  color: #337ab7;
  text-decoration: none;
}
.link-look:hover, .link-look:focus {
  color: #23527c;
  text-decoration: underline;
  cursor: pointer;
}
.pointer {
  cursor: pointer;
}
.header-page-title h1 {
  text-transform: uppercase;
  color: #364b5e;
  margin-bottom: 20px;
}

/* ID VERIFICATION */
.drop-zone {
  background: #A9A9A9;
  background-size: cover;
  margin-bottom: 20px;
}
#drop-zone-passport {
  background-image: url("/images/passport.png");
}
#drop-zone-face {
  background-image: url("/images/profile-photo.png");
}
#drop-zone-card-front {
  background-image: url("/images/id-front.png");
}
#drop-zone-card-back {
  background-image: url("/images/id-back.png");
}
.drop-zone-preview {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  max-width: 90%;
  max-height: 90%;
  display: block;
}
.remove-id {
  position: absolute;
  top: 5%;
  right: 5%;
  z-index: 100;
  background-color: #F9F9F9;
}
.remove-id .fa-times {
  color: firebrick;
}
.remove-id .fa-times:hover {
  cursor: pointer;
  color: red;
}
.modal-open {
  padding-right: 0px !important;
}