.Loader {
  margin-top: 100px;
  width: 100%;
}

.Loader-full-page {
  position: fixed;
  min-height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #f7f7f7;
  opacity: 0.7;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  z-index: 999;
}

.Loader-opaque {
  opacity: 1;
  z-index: 9999999999;
}

.Loader-spin-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.Loader-spin {
  position: relative;
}
.Loader-spin:before {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background: blue;
  content: '';
  position: absolute;
  background: #dd6b20;
  -webkit-animation: preloader_3_before 1.5s infinite ease-in-out;
  -moz-animation: preloader_3_before 1.5s infinite ease-in-out;
  -ms-animation: preloader_3_before 1.5s infinite ease-in-out;
  animation: preloader_3_before 1.5s infinite ease-in-out;
}
.Loader-spin:after {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  content: '';
  position: absolute;
  background: #dd6b20;
  animation: preloader_3_after 1.5s infinite ease-in-out;
}
@-moz-keyframes preloader_3_before {
  0% {
    transform: translateX(0px) rotate(0deg)
  }
  50% {
    transform: translateX(50px) scale(1.2) rotate(260deg);
    background: #dd6b20;
    border-radius: 0px;
  }
  100% {
    transform: translateX(0px) rotate(0deg)
  }
}
@-webkit-keyframes preloader_3_before {
  0% {
    transform: translateX(0px) rotate(0deg)
  }
  50% {
    transform: translateX(50px) scale(1.2) rotate(260deg);
    background: #dd6b20;
    border-radius: 0px;
  }
  100% {
    transform: translateX(0px) rotate(0deg)
  }
}
@keyframes preloader_3_before {
  0% {
    transform: translateX(0px) rotate(0deg)
  }
  50% {
    transform: translateX(50px) scale(1.2) rotate(260deg);
    background: #dd6b20;
    border-radius: 0px;
  }
  100% {
    transform: translateX(0px) rotate(0deg)
  }
}
@-moz-keyframes preloader_3_after {
  0% {
    transform: translateX(0px)
  }
  50% {
    transform: translateX(-50px) scale(1.2) rotate(-260deg);
    background: #dd6b20;
    border-radius: 0px;
  }
  100% {
    transform: translateX(0px)
  }
}
@-webkit-keyframes preloader_3_after {
  0% {
    transform: translateX(0px)
  }
  50% {
    transform: translateX(-50px) scale(1.2) rotate(-260deg);
    background: #dd6b20;
    border-radius: 0px;
  }
  100% {
    transform: translateX(0px)
  }
}
@keyframes preloader_3_after {
  0% {
    transform: translateX(0px)
  }
  50% {
    transform: translateX(-50px) scale(1.2) rotate(-260deg);
    background: #dd6b20;
    border-radius: 0px;
  }
  100% {
    transform: translateX(0px)
  }
}
