.VerificationForm {
  padding: 40px 20px 20px;
}

.VerificationForm input[type=number]::-webkit-inner-spin-button,
.VerificationForm input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  color: #2e3748 !important;
  font-family: din-2014 !important;
  letter-spacing: .9px;
}